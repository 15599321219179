import React, { useState, useEffect } from 'react';
import { useVisibilityChange } from "@uidotdev/usehooks";
import {
  Paper,
  Toolbar,
  Typography,
} from '@mui/material';

import { useSession } from 'store';
import { Page, SimpleTable } from 'components';
import { callApi } from 'utils';

function loopReload(active, cb, delay) {
  if (!active)
    return;

  const getStatus = () => callApi(`/systems`).then(cb).catch(console.error);

  getStatus();
  const itvl = setInterval(() => getStatus(), delay);
  return () => clearInterval(itvl);
}

const formatDelay = (row, col) => {
  const val = row[col];
  const color = val[0] === '-' ? 'inherit' : 'red';
  return (<span style={{color}}>{val}</span>);
};

const System = () => {
  const documentVisible = useVisibilityChange();
  const session = useSession((state) => state.session);
  const isAdmin = session.role === 'admin';

  const [data, setData] = useState(null);

  useEffect(() => loopReload(documentVisible, setData, 60_000), [documentVisible]);

  const title = data?.title || 'Systems';

  if (!isAdmin) {
    return (
      <Page title="Goodday">
        <Typography variant="h6" component="div">Have a nice day!</Typography>
      </Page>
    );
  }
  return (
    <Page title={title}>
      <Toolbar sx={{mt: -1}}>
        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>{title}</Typography>
      </Toolbar>
      <Paper sx={{p: 1, pb: 0, m: 2, mt: -1}}>
        <SimpleTable
          stickyHeader
          data={data}
          formatValue={{imp_delay: formatDelay, clk_delay: formatDelay}}
        />
      </Paper>
    </Page>
  );
};

export default System;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from '@mui/material';

import { formatFieldName } from 'utils';

const defaultStyle = Object.freeze({
  minWidth: 650,
  '& th,td': {
    padding: 1,
    textAlign: 'center',
    whiteSpace: 'pre-line',
  }
});

const defaultValueFormat = (row, col) => row[col];

const SimpleTable = ({data, pagination, onPagination, headerNames, formatValue, selected, onSelect, styles, ...others}) => {
  const finalStyle = { ...defaultStyle, ...styles };
  const cols = data?.columns, rows = data?.rows || [], total = data?.total || 0;
  const nrows = rows.length;

  const handlePageChange = useCallback((_, page) => onPagination(prev => [page, prev[1]]), [onPagination]);
  const handleRowsPerPageChange = useCallback((ev) => onPagination(prev => [prev[0], ev.target.value]), [onPagination]);

  const handleSelect = useCallback((ev) => onSelect(prev => {
    const t = ev.target, v = Number(t.value);
    const next = [...prev];
    const i = next.indexOf(v);
    if (t.checked) {
      i === -1 && next.push(v);
    } else {
      i >= 0 && next.splice(i, 1);
    }
    return next;
  }), [onSelect]);
  const handleSelectAll = useCallback((ev) => onSelect(() => {
    return ev.target.checked ? [...Array(nrows).keys()] : [];
  }), [onSelect, nrows]);

  if (!cols?.length) {
    return (<Box>No table columns</Box>);
  }

  const vfs = Object.fromEntries(cols.map(col => [col, formatValue[col] || defaultValueFormat]));
  const multiSelect = Array.isArray(selected);
  const allSelected = selected?.length === nrows;

  return (
    <Box>
      <TableContainer sx={{overflowX: "initial"}}>
        <Table stickyHeader sx={finalStyle} {...others}>
          <TableHead>
            <TableRow>
              {multiSelect && (
              <TableCell><Checkbox size="small" sx={{p:'3px'}} checked={allSelected} onChange={handleSelectAll} /></TableCell>
              )}
              {cols.map(col => (<TableCell key={col}>{headerNames[col] || formatFieldName(col)}</TableCell>))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!nrows && (<TableRow><TableCell colSpan={cols.length}>No data</TableCell></TableRow>)}
            {rows.map((row, idx) => (
              <TableRow hover key={row.key || row.id || idx}>
                {multiSelect && (
                <TableCell>
                  <Checkbox value={idx} size="small" sx={{p:'3px'}} checked={selected.includes(idx)} onChange={handleSelect} />
                </TableCell>
                )}
                {cols.map(col => (<TableCell key={col}>{vfs[col](row, col)}</TableCell>))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {total > 0 && (
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={pagination[0]}
        rowsPerPage={pagination[1]}
        rowsPerPageOptions={[30, 50, 100]}
      />
      )}
    </Box>
  );
};

SimpleTable.propTypes = {
  data: PropTypes.object,
  pagination: PropTypes.arrayOf(PropTypes.number),
  onPagination: PropTypes.func,
  headerNames: PropTypes.object,
  formatValue: PropTypes.object,
  selected: PropTypes.arrayOf(PropTypes.number),
  onSelect: PropTypes.func,
  styles: PropTypes.object,
};

SimpleTable.defaultProps = {
  styles: {},
  headerNames: {},
  formatValue: {},
};

export default SimpleTable;

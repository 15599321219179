import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  Paper,
} from '@mui/material';

import { Page, SimpleTable } from 'components';
import { callApi } from 'utils';

function reload([page, limit], cb) {
  callApi(`/jobs/changelist?page=${page}&limit=${limit}`)
    .then(cb).catch(console.error);
}

const formatContent = (row, col) => {
  const val = row[col] || {};
  return Object.keys(val).join(', ');
}
const formatDateTime = (row, col) => dayjs(row[col]).format('YYYY-MM-DD HH:mm:ss');

const Changelist = () => {
  const [pagination, setPagination] = useState([0, 50]);
  const [data, setData] = useState(null);

  useEffect(() => reload(pagination, setData), [pagination]);

  const title = data?.title || 'Changelist';
  return (
    <Page title={title}>
      <Paper sx={{p: 1, pb: 0, m: 2, mt: 1}}>
        <SimpleTable
          stickyHeader
          data={data}
          formatValue={{date: formatDateTime, content: formatContent}}
          pagination={pagination}
          onPagination={setPagination}
        />
      </Paper>
    </Page>
  );
};

export default Changelist;
